import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import {VIDEO_DISPLAYER_LIST} from "../../client-data/clientOptions";
import {Container} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import VideoFullScreenModal from "./VideoFullScreenModal";

const IMAGE_SRC_PATH = process.env.PUBLIC_URL + '/paintings/Thumbnails/';

export default class VideoDisplayerComponent extends Component {

    constructor(props) {
        super(props);

        this.onClickThumbnail = this.onClickThumbnail.bind(this);
    }

    onClickThumbnail = (videoDataObj) => {
        // window.alert("Play Video:" + videoDataObj.videoId);
        this.props.onClickThumbnail(videoDataObj)
    }

    render() {
        let rowStyle = this.props.index !== 10 ? "video-displayer-rows" : "video-displayer-last-row";
        return (
            <Container>
                <Row className={rowStyle} noGutters={true}>
                    <Col xs={12} md={4}>
                        <a onClick={() => this.onClickThumbnail(this.props.videoData)}>
                            <div className="video-displayer-container">
                                <Image className="video-displayer-image"
                                       src={process.env.PUBLIC_URL + this.props.videoData.thumbnailUrl}
                                       alt="Thumbnail Image"
                                       fluid={true}
                                />
                                <div className="video-displayer-middle">
                                    <div className="video-displayer-text">Play Video</div>
                                </div>
                            </div>
                        </a>
                    </Col>
                    <Col className="text-left" xs={12} md={8}>
                        <Row
                            className="video-displayer-text-row"><b>{this.props.videoData.headerText}</b></Row>
                        <Row
                            className="video-displayer-text-row"><i>{this.props.videoData.artistName}</i></Row>
                        <Row className="video-displayer-text-row"><i>{this.props.videoData.moreInfo}</i></Row>
                        <Row
                            className="video-displayer-text-row video-displayer-text-row-body-text">{this.props.videoData.bodyText}</Row>
                        <Row
                            className="video-displayer-text-row"><i>{this.props.videoData.datePublished}</i></Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}