import {MeshButtonBase} from "./MeshButtonBase";
import * as THREE from "three";
import {
    ButtonMaterials, HoverState,
    MeshButtonCallback,
    PAUSE_MATERIAL_COLOR,
    PLAY_MATERIAL_COLOR,
    TRANS_MATERIAL_COLOR, VIDEO_BUTTON_Y_OFFSET
} from "./ButtonsTypes";
import * as TWEEN from "@tweenjs/tween.js";
import {ButtonState} from "./VideoMeshButton";

export class AnimatedMeshButton extends MeshButtonBase {

    private BUTTON_DEFAULT_MATERIAL: THREE.MeshPhysicalMaterial = new THREE.MeshPhysicalMaterial({});
    private BUTTON_HOVER_MATERIAL: THREE.MeshPhysicalMaterial = new THREE.MeshPhysicalMaterial({});
    private BUTTON_TRANS_MATERIAL: THREE.MeshPhysicalMaterial = new THREE.MeshPhysicalMaterial({});

    static buttonMaterialsRecords: Record<number, ButtonMaterials> = {};
    protected lastUsedMaterial?: THREE.Material;

    constructor(private scene: THREE.Scene,
                button: THREE.Mesh,
                buttonId: string,
                onClickCallback?: MeshButtonCallback,
                onHoverOverCallback?: MeshButtonCallback,
                onHoverOutCallback?: MeshButtonCallback) {

        super(button, buttonId, onClickCallback, onHoverOverCallback, onHoverOutCallback);


        const btnMat = this.initButtonAnimatedMaterials();
        this.BUTTON_DEFAULT_MATERIAL = btnMat.playMaterial;
        this.BUTTON_HOVER_MATERIAL = btnMat.pauseMaterial;
        this.BUTTON_TRANS_MATERIAL = btnMat.transMaterial;

        this.setInitialButtonMaterial(this.BUTTON_DEFAULT_MATERIAL);


    }

    protected setInitialButtonMaterial = (material: THREE.Material) => {
        this.button.material = material; //
        this.lastUsedMaterial = material;
    }

    protected initButtonAnimatedMaterials = (): ButtonMaterials => {

        const materialId = this.meshMat.id;

        if (materialId in AnimatedMeshButton.buttonMaterialsRecords) {

            const btnMaterial: ButtonMaterials = {
                playMaterial: AnimatedMeshButton.buttonMaterialsRecords[materialId].playMaterial,
                pauseMaterial: AnimatedMeshButton.buttonMaterialsRecords[materialId].pauseMaterial,
                transMaterial: AnimatedMeshButton.buttonMaterialsRecords[materialId].transMaterial,
            }

            return btnMaterial;

        } else {
            this.BUTTON_DEFAULT_MATERIAL = new THREE.MeshPhysicalMaterial({
                name: 'VideoSwitchMeshBasicMatPlay',
                color: PLAY_MATERIAL_COLOR,
                metalness: 0.6,
                roughness: 0.4,
                clearcoat: 0.05,
                clearcoatRoughness: 0.05,
                envMapIntensity: 3.5
            });
            this.BUTTON_DEFAULT_MATERIAL.envMap = this.scene.environment;
            this.BUTTON_DEFAULT_MATERIAL.map = (<THREE.MeshStandardMaterial>this.meshMat).map;
            this.BUTTON_DEFAULT_MATERIAL.needsUpdate = true;

            this.BUTTON_HOVER_MATERIAL = new THREE.MeshPhysicalMaterial({
                name: 'VideoSwitchMeshBasicMatPause',
                color: PAUSE_MATERIAL_COLOR,
                metalness: 0.6,
                roughness: 0.4,
                clearcoat: 0.05,
                clearcoatRoughness: 0.05,
                envMapIntensity: 3.5
            });
            this.BUTTON_HOVER_MATERIAL.envMap = this.scene.environment;
            this.BUTTON_HOVER_MATERIAL.map = (<THREE.MeshStandardMaterial>this.meshMat).map;
            this.BUTTON_HOVER_MATERIAL.needsUpdate = true;

            this.BUTTON_TRANS_MATERIAL = new THREE.MeshPhysicalMaterial({
                name: 'VideoSwitchMeshBasicMatPause',
                color: TRANS_MATERIAL_COLOR,
                metalness: 0.6,
                roughness: 0.4,
                clearcoat: 0.05,
                clearcoatRoughness: 0.05,
                envMapIntensity: 3.5
            });

            this.BUTTON_TRANS_MATERIAL.envMap = this.scene.environment;
            this.BUTTON_TRANS_MATERIAL.toneMapped = false;
            this.BUTTON_TRANS_MATERIAL.needsUpdate = true;

            const btnMaterial: ButtonMaterials = {
                playMaterial: this.BUTTON_DEFAULT_MATERIAL,
                pauseMaterial: this.BUTTON_HOVER_MATERIAL,
                transMaterial: this.BUTTON_TRANS_MATERIAL,
            }
            AnimatedMeshButton.buttonMaterialsRecords[materialId] = btnMaterial;

            return btnMaterial;

        }
    }

    animateButton = () => {

        const xPos = this.button.position.x;
        const yPos = this.button.position.y;

        const buttonPos1 = this.button.position.clone();
        const buttonPos2 = buttonPos1.clone();
        buttonPos2.z -= VIDEO_BUTTON_Y_OFFSET;

        this.button.material = <THREE.Material>this.BUTTON_TRANS_MATERIAL;
        this.lastUsedMaterial = this.button.material;

        const t1 = new TWEEN.Tween(buttonPos1).to({
            x: xPos,
            y: yPos,
            z: buttonPos1.z - VIDEO_BUTTON_Y_OFFSET

        }, 1000)
            .onUpdate(pos => {
                this.button.position.copy(pos);
            }).onComplete(() => {



            });

        const t2 = new TWEEN.Tween(buttonPos2).to({
            x: xPos,
            y: yPos,
            z: buttonPos2.z + VIDEO_BUTTON_Y_OFFSET
        }, 500)
            .onUpdate(pos => {
                this.button.position.copy(pos);
            }).onComplete(() => {

                this.button.material = <THREE.Material>this.BUTTON_DEFAULT_MATERIAL;
                this.lastUsedMaterial = this.button.material;

            });

        t1.chain(t2);
        t1.start()
    }

    hoverOver = () => {
        if (this.disabled) return;

        if (this.hoverState === HoverState.HOVER_OVER) {
            return;
        }

        document.body.style.cursor = "pointer";

        this.button.material = <THREE.Material>this.BUTTON_HOVER_MATERIAL;
        this.hoverState = HoverState.HOVER_OVER;

        if (this.onHoverOverCallback) {
            this.onHoverOverCallback(this.buttonId)
        }

    }

    hoverOut = () => {

        if (this.disabled) return;

        if (this.hoverState === HoverState.HOVER_OUT) {
            return;
        }
        this.button.material = this.lastUsedMaterial!;
        this.hoverState = HoverState.HOVER_OVER;
        document.body.style.cursor = "default";

        if (this.onHoverOutCallback) {
            this.onHoverOutCallback(this.buttonId)
        }
        this.hoverState = HoverState.HOVER_OUT;
    }

    buttonClicked = () => {

        if (this.disabled) return;
        
        this.animateButton();
        if (this.onClickCallback) {
            this.onClickCallback(this.buttonId);
        }
    }
}
