import * as THREE  from "three";
import {Pathfinding} from "../pathfinding"
import {NavMeshPlayer} from "./NavMeshPlayer";
import {CompletePlayerCallBack, UpdatePlayerCallBack} from "./NavMeshUtils";
import {Object3D} from "three";


const NAVMESH_COLOR =  new THREE.Color( 0xFFFFFF ).convertGammaToLinear( 2.2 ).getHex();
const ZONE = 'level';

export class NavMeshContainer {

    private readonly _navMesh: THREE.Mesh;
    private readonly _pathFinder:Pathfinding;
    private readonly _zone:any;
    private _rayCaster: THREE.Raycaster = new THREE.Raycaster(new THREE.Vector3(),new THREE.Vector3(),0,2);

    constructor(private scene: THREE.Scene, private _theMesh:THREE.Mesh, private zoneName:string = ZONE) {

        if (!_theMesh || !_theMesh.isMesh) {
            throw new Error("The Navmesh must be Mesh");
        }
        this._navMesh = new THREE.Mesh(_theMesh.geometry, new THREE.MeshBasicMaterial({
            color: NAVMESH_COLOR,
            opacity: 0.01,
            transparent: true
        }));


        this._theMesh.geometry.dispose();
        const materials = Array.isArray(this._theMesh.material) ? this._theMesh.material : [this._theMesh.material];
        materials.forEach( mat => {
            mat.dispose();
        });
        this.scene.remove(this._theMesh);

        this.scene.add(this._navMesh);
        this._pathFinder = new Pathfinding();
        this._zone = Pathfinding.createZone( this._navMesh.geometry);
        this._pathFinder.setZoneData(this.zoneName,this._zone);
    }

    rayTraceNaveMesh = (origin: THREE.Vector3,direction:THREE.Vector3 = new THREE.Vector3(0,-1,0)) => {
        this._rayCaster.set(origin,direction);
        return this._rayCaster.intersectObject(this._navMesh,false);
    }

    createPlayer =(player: THREE.Object3D,onInitCallBack:UpdatePlayerCallBack = null,onStartPlayerCallBack:UpdatePlayerCallBack = null,
                   onUpdatePlayerCallBack:UpdatePlayerCallBack = null,
                   onCompletePlayerCallBack:CompletePlayerCallBack = null) => {
        return new NavMeshPlayer(this,player,this._pathFinder,this.zoneName,this._navMesh.position,onInitCallBack,onStartPlayerCallBack,onUpdatePlayerCallBack,onCompletePlayerCallBack);
    }

    get navMesh(): THREE.Mesh {
        return this._navMesh;
    }
}
