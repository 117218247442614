// import {Euler, Vector3, Scene} from "three";

const FLOOR_NUMBER = {
    BOTTOM_FLOOR: 1,
    TOP_FLOOR: 2,
};

const CameraLocationsDB = [
    // {
    //     cameraLocationId: 'gallery_door_01',Dy
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Gallery Door 01',
    //     ObjectId: null,
    //     displayMesh: 'N',
    // },
    // {
    //     cameraLocationId: '',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: 'Ground Floor',
    //     ObjectId: null,
    //     displayMesh: 'N',
    // },
    {
        cameraLocationId: 'ground_floor_01',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Workshops',
        ObjectId: null,
        displayMesh: 'N',
    },
    {
        // cameraLocationId: 'upper_gallery',
        cameraLocationId: 'gallery_door_01',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Upper Gallery',
        ObjectId: null,
        displayMesh: 'N',
    },
{
        cameraLocationId: 'studio_door_01',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Studio',
        ObjectId: null,
        displayMesh: 'N',
    },
  /*  {
        cameraLocationId: 'exit_and_gift_shop',
        floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
        locationName: 'Exit & Gift Shop',
        ObjectId: null,
        displayMesh: 'N',
    },*/
// {
    //     cameraLocationId: '',
    //     floorNumber: FLOOR_NUMBER.BOTTOM_FLOOR,
    //     locationName: '',
    //     ObjectId: null,
    //     displayMesh: 'Y',
    // },

];

export {CameraLocationsDB, FLOOR_NUMBER};
