import * as THREE from "three";


/*enum UPDATE_MODE  {
    vertices,
    transform,
    positionScaleOnly,
    none,
};*/



enum COLLIDER_CREATION_HINT {
    accurate,
    fast,
}

export enum COLLIDER_STATUS {
    intersected,
    not_intersected
}

export class ColliderX {


    public static idNumber = 0;
    public readonly id;
    public readonly updatedBox3 = new THREE.Box3();
    public readonly colliderName:string;
    private _colliderStatus = COLLIDER_STATUS.not_intersected;


    constructor(private object3d: THREE.Object3D,private showWireframe = false) {
        this.id = ColliderX.idNumber++;
        if (this.object3d.userData.colliderX_id) {
            this.colliderName = this.object3d.userData.colliderX_id;
        }
        else {
            this.colliderName = this.object3d.type //assume it is the camera
        }
        this.updatedBox3.setFromObject(this.object3d);

        if (this.showWireframe) {
            const theMesh = <THREE.Mesh>this.object3d;
            if (theMesh.isMesh) {
                const materials = Array.isArray(theMesh.material)? theMesh.material : [theMesh.material];
                materials.forEach((mat) => {
                    // @ts-ignore
                    mat.wireframe = true;
                })
            }
        }
        else {
            object3d.visible = false;
        }

        console.log("Colliderx -", this.colliderName, this.id, this.updatedBox3);

    }

    get colliderStatus(): COLLIDER_STATUS {
        return this._colliderStatus;
    }

    set colliderStatus(value: COLLIDER_STATUS) {
        this._colliderStatus = value;
    }

    collideWith	= (otherCollider: ColliderX): boolean => {
        return  this.updatedBox3.intersectsBox(otherCollider.updatedBox3);

    }
}
