import VideoRecord from "./VideoRecord";
import {VideosDB} from "../../../client-data/VideosDB";

export default class VideosDataController {

    private videoRecords: VideoRecord[] = [];

    constructor() {
        console.log("[VideosDataController] is created!");
        for(let videoRecord of VideosDB) {
            this.videoRecords.push(new VideoRecord(videoRecord));
        }
    }


    public getVideosData(): VideoRecord[] {
        return this.videoRecords;
    }


    public getVideoById(id:string): any {

        const index = this.videoRecords.findIndex((item) => {
            return item.videoId === id;
        });

        if (index !== -1 ) {
            return  this.videoRecords[index];
        }
        return null;
    }


};
