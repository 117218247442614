import * as THREE from "three";
import {VideoMeshButton} from "./VideoMeshButton";
import {VideoGroupMeshButton} from "./VideoGroupMeshButton";

export class VideoMeshButtonContainer {

    private raycaster = new THREE.Raycaster();
    private mouseVector = new THREE.Vector3();
    private meshesObj: Record<string, VideoMeshButton> = {};
    private groupMeshesObj: Record<string, VideoGroupMeshButton> = {};
    private selectedObject: THREE.Object3D | null = null;
    private selectedVideoButtonId: string | null = null;
    private selectedVideoGroupButtonId: string | null = null;
    private selectedMeshId: number | null = null;

    constructor(private scene: THREE.Scene, private camera: THREE.Camera, private buttons: Array<THREE.Mesh>, private groupButtons: Array<THREE.Mesh>) {
        buttons.forEach(btn => {
            this.meshesObj[btn.userData.videoButtonId] = new VideoMeshButton(this.scene, btn);
        });

        groupButtons.forEach(btn => {
            this.groupMeshesObj[btn.userData.videoGroupButtonId] = new VideoGroupMeshButton(this.scene, btn);
        })

    }

    getVideoMeshButton = (videoButtonId: string) => {
        const btnRec = this.meshesObj[videoButtonId];
        if (btnRec) {
            return btnRec;
        }
        return null;

    }

    getVideoGroupMeshButton = (videoGroupButtonId: string) => {
        const btnRec = this.groupMeshesObj[videoGroupButtonId];
        if (btnRec) {
            return btnRec;
        }
        return null;

    }


    meshSelected = (videoButtonId: string, node: THREE.Object3D): void => {

        const btnRec = this.meshesObj[videoButtonId];

        if (btnRec) {
            btnRec.buttonClicked();
        }
    }

    groupMeshSelected = (videoGroupButtonId: string, node: THREE.Object3D): void => {

        const btnRec = this.groupMeshesObj[videoGroupButtonId];

        if (btnRec) {
            btnRec.buttonClicked();
        }
    }


    hoverOver = (videoButtonId: string | null, videoGroupButtonId: string | null): void => {

        if (videoButtonId) {

            const btnRec = this.getVideoMeshButton(videoButtonId)

            if (btnRec) {
                btnRec.hoverOver();
            }
        }
        else  if (videoGroupButtonId) {

            const btnRec = this.getVideoGroupMeshButton(videoGroupButtonId)

            if (btnRec) {
                btnRec.hoverOver();
            }
        }

    }

    hoverOut = (videoButtonId: string | null, videoGroupButtonId: string | null): void => {

        if (videoButtonId) {

            const btnRec = this.getVideoMeshButton(videoButtonId)

            if (btnRec) {
                btnRec.hoverOut();
            }
        }
        else  if (videoGroupButtonId) {

            const btnRec = this.getVideoGroupMeshButton(videoGroupButtonId)

            if (btnRec) {
                btnRec.hoverOut();
            }
        }
    }

    onDocumentMouseMove = (event: MouseEvent): boolean => {

        let intersects: THREE.Intersection[] = [];

        intersects = this.getIntersects(event.clientX, event.clientY);
        const intersectLength = intersects.length;
        if (intersectLength > 0) {


            let res = intersects[0];
            const videoButtonId = res.object.userData.videoButtonId;
            const videoGroupButtonId = res.object.userData.videoGroupButtonId;
            if ((videoGroupButtonId && (videoGroupButtonId in this.groupMeshesObj)) || (videoButtonId && (videoButtonId in this.meshesObj))) {
                if (this.selectedMeshId) {
                    if (this.selectedMeshId !== res.object.id) {
                        this.hoverOut(this.selectedVideoButtonId, this.selectedVideoGroupButtonId);
                        this.hoverOver(videoButtonId, videoGroupButtonId);
                        this.selectedMeshId = res.object.id
                        this.selectedVideoButtonId = videoButtonId;
                        this.selectedVideoGroupButtonId = videoGroupButtonId;

                    }

                } else {
                    this.hoverOver(videoButtonId, videoGroupButtonId);
                    this.selectedMeshId = res.object.id
                    this.selectedVideoButtonId = videoButtonId;
                    this.selectedVideoGroupButtonId = videoGroupButtonId;
                }

                return true;
            } else {

                if (this.selectedMeshId) {
                    this.hoverOut(this.selectedVideoButtonId, this.selectedVideoGroupButtonId);
                    this.selectedMeshId = null;
                    this.selectedVideoButtonId = null;
                    this.selectedVideoGroupButtonId = null;
                }
                return false;
            }
        }
        if (this.selectedMeshId) {
            this.hoverOut(this.selectedVideoButtonId, this.selectedVideoGroupButtonId);
            this.selectedMeshId = null;
            this.selectedVideoButtonId = null;
            this.selectedVideoGroupButtonId = null;
        }
        return false;

    }


    onDocumentMouseDown = (event: MouseEvent, useForHovering = false): boolean => {

        let intersects: THREE.Intersection[] = [];

        intersects = this.getIntersects(event.clientX, event.clientY);
        const intersectLength = intersects.length;
        if (intersectLength > 0) {


            let res = intersects[0];
            const videoButtonId = res.object.userData.videoButtonId;
            const videoGroupButtonId = res.object.userData.videoGroupButtonId;

            if (videoGroupButtonId && (videoGroupButtonId in this.groupMeshesObj)) {

                this.selectedObject = res.object;

                this.groupMeshSelected(videoGroupButtonId, this.selectedObject);

                return true;

            } else if (videoButtonId && (videoButtonId in this.meshesObj)) {

                this.selectedObject = res.object;

                this.meshSelected(this.selectedObject.userData.videoButtonId, this.selectedObject);

                return true;
            }
        }

        return false;

    }

    getIntersects = (x: number, y: number): THREE.Intersection[] => {

        x = (x / window.innerWidth) * 2 - 1;
        y = -(y / window.innerHeight) * 2 + 1;

        this.mouseVector.set(x, y, 0.5);
        this.raycaster.setFromCamera(this.mouseVector, this.camera);

        return this.raycaster.intersectObjects([...this.buttons, ...this.groupButtons], true);

    }
}
