import {HoverState, MeshButtonCallback} from "./ButtonsTypes";
import * as THREE from "three";

const DEFAULT_HOVER_OVER_COLOR = new THREE.Color(1, 0, 0);

export class MeshButtonBase {




    protected hoverState = HoverState.HOVER_OUT;
    protected _meshMat: THREE.Material;
    protected buttonOriginalColor: THREE.Color;
    private _disabled = false;


    constructor(private _button: THREE.Mesh,
                private _buttonId: string,
                private _onClickCallback?: MeshButtonCallback,
                private _onHoverOverCallback?: MeshButtonCallback,
                private _onHoverOutCallback?: MeshButtonCallback) {


        this._meshMat = this.getMaterial(this._button.material);
        this.buttonOriginalColor = this.getButtonOriginColour();


        _button.matrixAutoUpdate = true;
        _button.updateMatrix();
    }

    protected getMaterial = (material: THREE.Material | Array<THREE.Material>): THREE.Material => {
        let objMat;
        if (Array.isArray(material)) {
            objMat = material[0]
        } else {
            objMat = material;
        }
        return objMat;

    }

    get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = value;
    }

    protected getButtonOriginColour = (): THREE.Color => {
        return (<THREE.MeshStandardMaterial>this._meshMat).color
    }

    protected setMeshButtonColor = (color: THREE.Color) => {

        (<THREE.MeshStandardMaterial>this._meshMat).color = color;
    }

    protected getDefaultHoverOverColour = (): THREE.Color => {
        return DEFAULT_HOVER_OVER_COLOR;
    }

    get meshMat(): THREE.Material {
        return this._meshMat;
    }

    get button(): THREE.Mesh {
        return this._button;
    }

    get buttonId(): string {
        return this._buttonId;
    }

    get onClickCallback(): MeshButtonCallback | undefined {
        return this._onClickCallback;
    }

    get onHoverOverCallback(): MeshButtonCallback  | undefined {
        return this._onHoverOverCallback;
    }

    get onHoverOutCallback(): MeshButtonCallback  | undefined {
        return this._onHoverOutCallback;
    }


    set onClickCallback(value: MeshButtonCallback  | undefined) {
        this._onClickCallback = value;
    }

    set onHoverOverCallback(value: MeshButtonCallback  | undefined) {
        this._onHoverOverCallback = value;
    }

    set onHoverOutCallback(value: MeshButtonCallback  | undefined) {
        this._onHoverOutCallback = value;
    }

    hoverOver = () => {

        if (this.disabled) return;

        if (this.hoverState === HoverState.HOVER_OVER) {
            return;
        }

        document.body.style.cursor = "pointer";

        if (this.onHoverOverCallback) {
            this.onHoverOverCallback(this.buttonId)
        }
        this.setMeshButtonColor(this.getDefaultHoverOverColour());
        this.hoverState = HoverState.HOVER_OVER;
    }

    hoverOut = () => {

        if (this.disabled) return;

        if (this.hoverState === HoverState.HOVER_OUT) {
            return;
        }
        this.setMeshButtonColor(this.buttonOriginalColor);
        document.body.style.cursor = "default";

        if (this.onHoverOutCallback) {
            this.onHoverOutCallback(this.buttonId)
        }
        this.hoverState = HoverState.HOVER_OUT;
    }

    buttonClicked = () => {
        if (this.disabled) return;

        if (this.onClickCallback) {
            this.onClickCallback(this.buttonId);
        }
    }
}
