import {Color} from "three";
import * as THREE from "three";

export const MATERIAL_PROPS_DB = [


    /*  {
          materialName: "FloorMat",
          aOIntensity:0.17977839244718613,
          envMapIntensity:0.8,
          roughness:0.57,

      },*/

    {
        materialName: "GlassMat",
        glass: true,
        glassMatFunc: (envMap) => {
            return new THREE.MeshPhysicalMaterial({
                name: `GlassMat-MPM`,
                color: new THREE.Color(255, 255, 255),
                metalness: 0,
                roughness: 0,
                // alphaMap: texture,
                alphaTest: 0.5,
                envMap: envMap, //this.backgroundMap,
                envMapIntensity: .35,
                depthWrite: false,
                transmission: .85, // use material.transmission for glass materials
                opacity: 1, // set material.opacity to 1 when material.transmission is non-zero
                transparent: true
            });
        },
    },

    /* {
         materialName: "VideoButtonBaseMat",

         envMapIntensity: 4.179193713700034,

     },*/





    /* {
         materialName: "LedgeEmmisiveMat",
         toneMapped: 'N',
     },*/






    /* {
         materialName: "LedgeMat",
         envMapIntensity: 5.0,
     },


     {
         materialName:"PannelsWhiteMat",
         aOIntensity:0.05136749429326262,
         envMapIntensity:3.9400817067635048,


     },*/





    {
        materialName: "CeilingMetalBars",
        // emmisiveColor:
        //     new Color( 0.01000000000000001, 0.01000000000000001, 0.01000000000000001 ),
        emmissiveIntensity: 1,
        envMapIntensity: 2.0,
        // roughness:0.11343067171319685,
        toneMapped: 'N',
    },

    {
        materialName: "PlinthCockMat",
        aOIntensity: 0.5971138114474376,

    },

    {
        materialName: "PlinthHeadMat",
        aOIntensity: 0.5650110869089567,

    },

    {
        materialName: "PlinthCatMat",
        aOIntensity: 0.5971138114474376,

    },

    {
        materialName: "Cock_Beanard_Meadows",
        toneMapped: 'N',
    },

    {
        materialName: "HeadWithBlueRabbit",
        toneMapped: 'N',
    },

    {
        materialName: "Cat_RobertEClatworthy",
        toneMapped: 'N',
    },

    {
        materialName: "I_UVT_01",
        toneMapped: 'N',
    },
    {
        materialName: "I_UVT_02",
        toneMapped: 'N',
    },

    {
        materialName: "DoorsMat",
        aOIntensity: 0.7255247096013612,
        envMapIntensity: 5.363302494636157,

    },

    {
        materialName: "Stair_wood",
        aOIntensity: 0.3402920151395905,
        roughness: 0.57,

    },

    {
        materialName: "WallsMat",
        /* aOIntensity: 0.6,
         envMapIntensity: 1.3,*/

        aOIntensity: 0.5944888586494301,
        envMapIntensity: 2.2253784657254636,
        roughness: 1,

    },

    {
        materialName: "StairsHandleMat",

        envMapIntensity: 7,
        roughness:0.2743663888416397,

    },

    {
        materialName: "DFLT_FRAME_MAT_PAPER",
        aOIntensity: 1.17,

        envMapIntensity: 5.1680078125,

    },
    {
        materialName: "WindowMat",
        aOIntensity: 0.5,

    },


    {
        materialName: "FloorMat",
        aOIntensity: 0.5332775919732442,
        envMapIntensity: 0.8,
        roughness: 0.30604701894408215,
        toneMapped: false,
    },


    {
        materialName: "CeilingMat",
        aOIntensity: 0.5650110869089567,
        envMapIntensity: 0.8,

    },

    {
        materialName: "ColumnsMat",
        aOIntensity:0.8230991665249192,

        envMapIntensity: 7,
        // lightMapIntensity:1,
        roughness:0.6118387480864093,
        // toneMapped:true,
    },

    {
        materialName: "DeskPilarMat",
        aOIntensity: 0.73,

    },

    {
        materialName: "WhiteMetalMat",
        envMapIntensity: 1.0936401310181996,

    },


    {
        materialName: "SlidingDoorMat",
        aOIntensity: 0.53,
        envMapIntensity: 3.6,

    },


    {
        materialName: "CeilingLightModelMat",

        aOIntensity:0.6271474740602143,
        envMapIntensity:6,
        lightMapIntensity:1,
        roughness:0.8295628508249703,

    },


]



   /* [

        {
            materialName:"FloorMat",
            aOIntensity:0.6103518961393919,
            emmisiveColor:
                new Color( 0, 0, 0 ),
            emmissiveIntensity:1,
            envMapIntensity:0.8,
            lightMapIntensity:1,
            roughness:0.20345063204646396,
            toneMapped:true,
        },




    ]*/

/*
[


 ]
 */






