// export const ANNOTATION_TEXT_ALIGNMENT = "justify";
// export const ANNOTATION_TEXT_LINE_HEIGHT = "1.5";



const VideosDB = [

    {
        videoId: 'S01T11',
        videoUrl: 'S01V11.mp4',
        thumbnailUrl: `S01T11.jpg`,
        artistName: `Sovay Berriman`,
        headerText: `Sculptural Sketches of the Natural World`,
        moreInfo: `52 minutes – all ages`,
        bodyText: `Inspired by the life and work of Norman Adams, Sovay explores how to create 3D sketches in response to memories formed in the natural environment.`,
        datePublished: null,
        videoElement:'S01V11',
    },
    {
        videoId: 'S01T12',
        videoUrl: 'S01V12.mp4',
        thumbnailUrl: `S01T12.jpg`,
        artistName: `Lou Brett`,
        headerText: `Ink Resist`,
        moreInfo: `36 minutes – all ages`,
        bodyText: `Have you ever tried the magical technique of ink resist? Inspired by the still life paintings Stass Paraskos, Lou Brett will lead you through this intriguing process.`,
        datePublished: null,
        videoElement:'S01V12',
    },
    {
        videoId: 'S01T13',
        videoUrl: 'S01V13.mp4',
        thumbnailUrl: `S01T13.jpg`,
        artistName: `Sophie Butcher`,
        headerText: `Zine Making`,
        moreInfo: `52 minutes - age 11+`,
        bodyText: `Sophie explores the DIY culture of zine making as a way of collecting together thoughts and ideas, testing out a selection of different approaches.`,
        datePublished: null,
        videoElement:'S01V13',
    },
    {
        videoId: 'S01T14',
        videoUrl: 'S01V14.mp4',
        thumbnailUrl: `S01T14.jpg`,
        artistName: `Ali Corder`,
        headerText: `Canvas Patchwork Painting`,
        moreInfo: `49 minutes – all ages`,
        bodyText: `Inspired by the life and work of Norman Adam’s, Ali creates a patchwork painting which reflects the rapport between the painter and his wife, the poet Anne Adams.`,
        datePublished: null,
        videoElement:'S01V14',
    },
    {
        videoId: 'S01T15',
        videoUrl: 'S01V15.mp4',
        thumbnailUrl: `S01T15.jpg`,
        artistName: `Laura Drayson`,
        headerText: `Cutting, Shaping & Forming with Paper`,
        moreInfo: `62 minutes - 3 exercises for different abilities`,
        bodyText: `In collaboration paper artist Tony Crosby, Laura designed the set for the Palace of Culture. Join Laura here and explore the art of paper cutting using repeated patterns, inspired by beach finds.`,
        datePublished: null,
        videoElement:'S01V15',
    },
    {
        videoId: 'S01T16',
        videoUrl: 'S01V16.mp4',
        thumbnailUrl: `S01T16.jpg`,
        artistName: `Naomi Frears`,
        headerText: `Painting, Drawing & Offsetting`,
        moreInfo: `59 minutes – all ages`,
        bodyText: `Attending Stass Paraskos’ art school in Cyprus was pivotal in encouraging a young Naomi Frears to pursue a career as an artist. Here she recounts the warmth and generosity of Stass as she employs various creative responses to a selection of his work.`,
        datePublished: ``,
        videoElement:'S01V16',
    },
    {
        videoId: 'S01T17',
        videoUrl: 'S01V17.mp4',
        thumbnailUrl: `S01T17.jpg`,
        artistName: `Georgia Gendall`,
        headerText: `Kitchen Cupboard Automata`,
        moreInfo: `27 minutes – all ages`,
        bodyText: `Art meets design technology in this fun, and tasty, exploration of basic automata inspired by Julian Trevelyan’s use of everyday materials in his artwork and William Gear’s interest spontaneous creation.`,
        datePublished: ``,
    },
    {
        videoId: 'S01T18',
        videoUrl: 'S01V18.mp4',
        thumbnailUrl: `S01T18.jpg`,
        artistName: `Olivia Lowry`,
        headerText: `Creating a Pantoum Poem`,
        moreInfo: `60 minutes - age 11+`,
        bodyText: `Writer and performer Olivia Lowry will talk you through simple warm up techniques in preparation for the writing of a pantoum poem, an ancient Malaysian form of poetry which features repeating lines that change meaning as the poem progresses.`,
        datePublished: ``,
        videoElement:'S01V18',
    },
    {
        videoId: 'S01T19',
        videoUrl: 'S01V19.mp4',
        thumbnailUrl: `S01T19.jpg`,
        artistName: `Alice Mahony`,
        headerText: `Screen Printing at Home`,
        moreInfo: `55 minutes – all ages`,
        bodyText: `Ever wanted to try screen printing but thought you couldn’t because you don’t have specialist kit? Here Alice demonstrates how you can try this rewarding printing technique, cheaply at home.`,
        datePublished: ``,
        videoElement:'S01V19',
    },
    {
        videoId: 'S01T20',
        videoUrl: 'S01V20.mp4',
        thumbnailUrl: `S01T20.jpg`,
        artistName: `Rosanne Robertson`,
        headerText: `Sculpture with Plaster of Paris`,
        moreInfo: `58 minutes – all ages`,
        bodyText: `Inspired by the work of Alan Grimwood, Rosanne will lead you through how to effectively employ quick setting gypsum plaster to achieve satisfying sculptural forms using found and natural objects.`,
        datePublished: ``,
        videoElement:'S01V20',
    },
    {
        videoId: 'S01T21',
        videoUrl: 'S01V21.mp4',
        thumbnailUrl: `S01T21.jpg`,
        artistName: `Rosie Taylor-Hingston`,
        headerText: `Dance Choreography: Chance Method`,
        moreInfo: `30 minutes – all ages`,
        bodyText: `Riffing off of William Gear’s spontaneous creative processes, choreographer Rosie Taylor-Hingston collaborates with dancer Lillia Rowe to construct a dance sequence using chance.`,
        datePublished: null,
        videoElement:'S01V21',
    },
];

export {VideosDB};
