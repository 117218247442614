import React from "react";
import VideoComponent from "../util/VideoComponent";

const VideosComponent = (props) => {
    return (
        <div>
            {/* Videos */}
            <VideoComponent id="S01V11" src={process.env.PUBLIC_URL + `/videos/S01V11.mp4`}/>
       {/*     <VideoComponent id="S01V12" src={process.env.PUBLIC_URL + `/videos/S01V12.mp4`}/>
            <VideoComponent id="S01V13" src={process.env.PUBLIC_URL + `/videos/S01V13.mp4`}/>
            <VideoComponent id="S01V14" src={process.env.PUBLIC_URL + `/videos/S01V14.mp4`}/>
            <VideoComponent id="S01V15" src={process.env.PUBLIC_URL + `/videos/S01V15.mp4`}/>
            <VideoComponent id="S01V16" src={process.env.PUBLIC_URL + `/videos/S01V16.mp4`}/>
            <VideoComponent id="S01V17" src={process.env.PUBLIC_URL + `/videos/S01V17.mp4`}/>
            <VideoComponent id="S01V18" src={process.env.PUBLIC_URL + `/videos/S01V18.mp4`}/>
            <VideoComponent id="S01V19" src={process.env.PUBLIC_URL + `/videos/S01V19.mp4`}/>
            <VideoComponent id="S01V20" src={process.env.PUBLIC_URL + `/videos/S01V20.mp4`}/>
            <VideoComponent id="S01V21" src={process.env.PUBLIC_URL + `/videos/S01V21.mp4`}/>*/}
            <VideoComponent id="W06V22" src={process.env.PUBLIC_URL + `/videos/W06V22.mp4`}/>


            {/*/!*Audio*!/*/}
            <VideoComponent id="W01T02A01" src={process.env.PUBLIC_URL + `/audio/W01T02A01.mp3`} audio="Y"/>
            <VideoComponent id="W02P03A01" src={process.env.PUBLIC_URL + `/audio/W02P03A01.mp3`} audio="Y"/>
            <VideoComponent id="W03P05A01" src={process.env.PUBLIC_URL + `/audio/W03P05A01.mp3`} audio="Y"/>
            <VideoComponent id="W04P07A01" src={process.env.PUBLIC_URL + `/audio/W04P07A01.mp3`} audio="Y"/>
            <VideoComponent id="W05P08A01" src={process.env.PUBLIC_URL + `/audio/W05P08A01.mp3`} audio="Y"/>
            <VideoComponent id="W05P08A02" src={process.env.PUBLIC_URL + `/audio/W05P08A02.mp3`} audio="Y"/>
            <VideoComponent id="W02P03A02" src={process.env.PUBLIC_URL + `/audio/W02P03A02.mp3`} audio="Y"/>

        </div>
    )
}

export default VideosComponent;
