import * as THREE from "three";

export class AudioController {

    private _listener = new THREE.AudioListener();
    private _sound: THREE.Audio;
    constructor(private _scene: THREE.Scene, private _camera:THREE.Camera, audioElementId:string) {

        const source = this._listener.context.createBufferSource();
        source.connect(this._listener.context.destination);
        source.start();

        this._camera.add(this._listener);
        const  audioElement:HTMLAudioElement = <HTMLAudioElement>document.getElementById(audioElementId);
        audioElement.play();

        this._sound = new THREE.Audio( this._listener );
        this._sound.setMediaElementSource(audioElement);

    }

    playAudio = async (audoElement:HTMLMediaElement) => {
        await audoElement.play();
    }
}
