import VideoContainer from "../../components/annotations/VideoContainer";
import {MeshButtonContainer} from "../../components/annotations/ts/MeshButtonContainer";
import * as THREE from "three";
import {MeshButtonBase} from "../../components/annotations/ts/MeshButtonBase";
import {ColliderXContainer} from "../../components/colliders/ts/ColliderXContainer";
import {ColliderX} from "../../components/colliders/ts/ColliderX";
import {VIDEO_BUTTON_Y_OFFSET} from "../../components/annotations/ts/ButtonsTypes";
import * as TWEEN from "@tweenjs/tween.js";
import {CameraNavMeshHandler} from "../../components/three-gltf-loader/ts/CameraNavMeshHandler";
import VideoDisplay from "../../components/annotations/VideoDisplay";
import VideoRecord from "../../components/videos/ts/VideoRecord";
import {

    thisIsMobile
} from '../../client-data/GlobalConstants'
import {MeshContainer} from "../../components/annotations/ts/MeshContainer";

enum DOOR_STATUS {
    OPEN,
    CLOSED
}

const DOOR_Y_OPEN = 4.59657;
const DOOR_Y_CLOSED = 2.33469;
const SCREEN_ROM_CAMERA_POS = new THREE.Vector3(1.68148, 0.002, 2.33469);

export class NewlynVideoRoomController {

    private meshButton_btW01V01: MeshButtonBase;
    private meshButton_btDoorOpener: MeshButtonBase;
    private readonly _colliderXContainer: ColliderXContainer;
    private doorStatus = DOOR_STATUS.CLOSED;
    private videoDisplay_S01V11?: VideoDisplay;
    private mesh_S01V11: THREE.Object3D | null = null;
    private videoDisplay_W06V22: VideoDisplay;
    //colider door_closer
    //colider video_play
    // animation_id VideoRoomSlidingDoor
    //door closed y= -2.33469
    //door opened y= -4.59657
    // delta = 2.21688
    //studi video W06V22

    // camera point infront of screen vector3(1.68148, 1.5, 2.33469)


    constructor(private camera: THREE.Camera,
                private videoContainer: VideoContainer,
                private meshButtonContainer: MeshButtonContainer,
                private colliders: Array<THREE.Object3D>,
                private animatedMeshes: Array<THREE.Object3D>,
                private cameraNavMeshHandler: CameraNavMeshHandler,
                private openVideoMenuCallback: () => void,
                private hideVideoMenuCallback: () => void,
                private meshContainer: MeshContainer,
                showWireframe = false) {

        const colliderXs: Array<ColliderX> = [];
        this.colliders.forEach((collider) => {
            colliderXs.push(new ColliderX(collider, showWireframe));
        })

        this._colliderXContainer = new ColliderXContainer(camera, new THREE.Vector3(0.5, 0.5, 0.5), colliderXs, this.colliderEnter, this.colliderStay, this.colliderExit, showWireframe);

        this.meshButton_btW01V01 = this.meshButtonContainer.getMeshButton("btW01V01")!;
        this.meshButton_btW01V01.onClickCallback = this.buttonClicked_btW01V01;

        this.meshButton_btDoorOpener = this.meshButtonContainer.getMeshButton("btDoorOpener")!;
        this.meshButton_btDoorOpener.onClickCallback = this.buttonClicked_btDoorOpener;
        if (thisIsMobile) {
           this.mesh_S01V11 = this.meshContainer.getMeshByPaintingId("S01V11");
        }
        else {
            this.videoDisplay_S01V11 = this.videoContainer.getVideoDisplay("S01V11");
        }
        this.videoDisplay_W06V22 = this.videoContainer.getVideoDisplay("W06V22");

    }


    buttonClicked_btW01V01 = (buttonId: string): void => {
        if (this.doorStatus !== DOOR_STATUS.OPEN) return;

        console.log("buttonClicked- btW01V01", buttonId);
        if (this.openVideoMenuCallback) {
            this.openVideoMenuCallback();
        }
    }

    buttonClicked_btDoorOpener = (buttonId: string): void => {
        if (this.doorStatus === DOOR_STATUS.OPEN) return;
        console.log("buttonClicked btDoorOpener-", buttonId);
        this.doorStatus = DOOR_STATUS.OPEN;
        this.animateDoor(true);


    }


    colliderEnter = (collider: ColliderX): void => {
        console.log("colliderEnter-", collider)
        if (collider.colliderName === "video_play") {
            this.videoDisplay_W06V22.playVideo();
        }

    }

    colliderStay = (collider: ColliderX): void => {
        // console.log("colliderStay -",collider.colliderName)
    }

    colliderExit = (collider: ColliderX): void => {

        if (collider.colliderName === 'door_closer') {
            if (this.doorStatus === DOOR_STATUS.CLOSED) return;
            console.log("colliderExit-", collider)
            this.doorStatus = DOOR_STATUS.CLOSED;
            this.animateDoor(false);
            // this.hideVideoMenuCallback();
            if (!thisIsMobile) {
                this.videoDisplay_S01V11!.PauseVideo();
            }
        }
        else if (collider.colliderName === "video_play") {
                this.videoDisplay_W06V22.PauseVideo();
        }

    }

    get colliderXContainer(): ColliderXContainer {
        return this._colliderXContainer;
    }

    animateDoor = (open: boolean = true) => {

        const xPos = this.animatedMeshes[0].position.x;
        const yPos = this.animatedMeshes[0].position.y;
        const doorPos1 = this.animatedMeshes[0].position.clone();

        let door_y_pos = DOOR_Y_OPEN;

        if (!(open)) {
            door_y_pos = DOOR_Y_CLOSED;
        }
        const t1 = new TWEEN.Tween(doorPos1).to({
            x: xPos,
            y: yPos,
            z: door_y_pos

        }, 5000)
            .onUpdate(pos => {
                this.animatedMeshes[0].position.copy(pos);
            }).onComplete(() => {
                console.log("animateDoor")
                if (open) {
                    if (thisIsMobile) {
                        if (this.mesh_S01V11) {
                            this.cameraNavMeshHandler.navigateToTargetPos(this.mesh_S01V11);
                        }

                    }
                    else {
                        const videoMesh = this.videoDisplay_S01V11!.getVideoMesh();
                        this.cameraNavMeshHandler.navigateToTargetPos(videoMesh);
                    }
                    // this.cameraNavMeshHandler.navigateToNanMeshPoint(SCREEN_ROM_CAMERA_POS);
                }

            });
        t1.start()

    }

    public onSelectVideoThumbNail = (videoRecord: VideoRecord) => {
        console.log("onSelectVideoThumbNail=", videoRecord);
        this.hideVideoMenuCallback();
        //alert(`Video selected - ${videoRecord.headerText}`);
       // if (this.videoDisply.isPlaying) {
        if (!thisIsMobile) {
            this.videoDisplay_S01V11!.PauseVideo();
        }
        //}

        if (!thisIsMobile) {
            setTimeout(() => {
                // this.videoDisply.resetVideoElement(videoRecord.videoElement);
                //this.videoDisply.resetVideoDisplayMaterialTexture();

                this.videoDisplay_S01V11!.resetVideoSource(videoRecord.videoUrl);
                this.videoDisplay_S01V11!.playVideo();
            }, 2000);
        }


    }
}
